















































import { Component, Vue } from 'vue-property-decorator';
import myHeader from '@/components/Header.vue';
import myFooter from '@/components/Footer.vue';
import { getContent, getMessage, putSaveState } from '@/api/myNews/myNews.ts';

@Component({
    components: {
        myHeader,
        myFooter
    }
})
export default class MyNews extends Vue {
  data = {
      semesterId: 0
  };

  loading = true;
  activeindex = -1;
  index = '';
  showtime = 0;
  messageList = [{
      // eslint-disable-next-line @typescript-eslint/camelcase
      announce_id: 0,
      anID: 0,
      state: 3

  }];

  msgNum = 0;
  oneState: any;
  messageContent = {
      content: '',
      title: '',
      sendTime: '',
      state: 3
  };

  created() {
      this.getData();
  }

  // 方法
  getData() {
      this.data.semesterId = parseInt(localStorage.getItem('semesterId') as string);
      getMessage(this.data).then((res: any) => {
          if (res.code === 200) {
              this.messageList = res.data;
              this.msgNum = Number(res.msg_num);
              // 保存第一条消息的状态
              this.oneState = res.data[0].state;
              // 让第一条是阅读的状态
              // this.messageList[0].state = 2;
              // 赋值到左边 显示内容
              this.messageContent.content = '';
              this.messageContent.title = '';
              this.messageContent.sendTime = '';
              this.messageContent.state = 0;
          } else {
              this.$message.error(res.error);
          }
          this.loading = false;
      }).catch((error: any) => {
          console.log(error);
      });
  }

  // 渲染时自动调用1转换01
  courseTitleValue(index: number) {
      index++;
      return index >= 10 ? index.toString() : '0' + index;
  }

  readMess(item: any, index: any) {
      this.activeindex = index;
      if (this.index === index) {
          return '';
      }

      item.state = 2;
      this.showtime = 1;

      // 修改阅读状态
      this.saveState(this.messageList[index].announce_id, this.messageList[index].state, index);

      // 赋值左侧内容
      // this.messageContent.content = item.content;
      this.getcontent(this.messageList[index].anID);
      this.messageContent.title = item.title;
      this.messageContent.sendTime = item.send_time;
      this.messageContent = { ...this.messageContent };
  }

  saveState(announceId: number, state: number, index: number) {
      // 修改选中的这一条消息
      const data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
          announce_id: announceId,
          state: state
      };
      putSaveState(data).then((res: any) => {
          if (res.code === 200) {
              console.log(res);
              this.messageList[index].state = 2;
              this.msgNum--;
              this.$emit('getMessage', this.msgNum);
          }
      }).catch((error: any) => {
          console.log(error);
      });
  }

  getcontent(announceId: number) {
      const data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
          announce_id: announceId
      };
      getContent(data).then((res: any) => {
          if (res.code === 200) {
              this.messageContent.content = res.data;
          } else {
              this.messageContent.content = '网络发生错误！请稍候再试';
          }
      });
  }
}
